import { marked } from 'marked';

import DOMPurify from '@/lib/DOMPurify';

import removeStyle from '@/utils/removeStyles';

const renderer = new marked.Renderer();
renderer.link = function (href, title, text): string {
  const html = marked.Renderer.prototype.link.call(renderer, href, title, text);
  return href?.startsWith('http') // All external links will open in a new tab without referrer
    ? html
    : html.replace(/^<a /, `<a target="_blank" rel="noreferrer noopener nofollow" `);
};

export interface MarkdownResult {
  rendered: string;
  cropped: boolean;
}

export function renderMarkdown(markdown: string, max = 0): MarkdownResult {
  const indent = markdown.match(/^\n */)?.[0]?.length ?? 0;
  if (indent > 0) {
    markdown = markdown
      .split('\n')
      .map(line => line.slice(indent - 1))
      .join('\n');
  }

  let result = '';
  let count = 0;

  let escaped = false;
  let cropped = false;

  const stack: string[] = [];

  for (let i = 0; i < markdown.length; i++) {
    const char = markdown[i];
    result += char;

    let match;
    if (!escaped) {
      if (char === '\\') {
        escaped = true;
      } else if ((match = markdown.substring(+i).match(/^(\*\*|~~|__|[_*])/g)?.[0])) {
        if (stack[stack.length - 1] === match) {
          stack.pop();
        } else if (match !== '_') {
          stack.push(match);
        }

        i += match.length - 1;
        result += match.substring(1);
      } else if (
        markdown[i - 1] !== '!' &&
        (match = markdown.substring(+i).match(/^(!?)\[(.*)]\((.*)\)/))
      ) {
        const [whole, prefix, link, text] = match;
        const newText = text;
        result = result.substring(0, result.length - 1);
        result += `${prefix}[${link}](${max > 0 ? newText?.substring(0, max - count) : text})`;
        count += newText ? newText.length : 0;
        i = +i + whole.length - 1;
      } else {
        count++;
      }
    } else {
      escaped = false;
    }

    if (max > 0 && count >= max) {
      cropped = true;
      break;
    }
  }

  const html = marked.parse(result.trim() + stack.reverse().join('') + (cropped ? '...' : ''), {
    renderer
  });

  const sanitizedHtml = DOMPurify.sanitize(html, { ADD_TAGS: ['iframe'], ADD_ATTR: ['target'] });

  return {
    rendered: removeStyle(sanitizedHtml),
    cropped
  };
}
